<template lang="pug">
  main
    .register(v-if="!signed")
      .register__wrapper.password-reset
        a.register__logo(@click="goRoot")
          include ../../assets/images/logo_title.svg
        .register__form
          button.register__form-close(@click="goRoot")
          .register__form-tabs
            span.password-reset__form-header НОВЫЙ ПАРОЛЬ
          ValidationObserver(ref="form")
            form.register__form-item(
              @submit.prevent="updatePassword"
              role="form")

                label.register__form-label
                  span.register__form-label-title Пароль
                ValidationProvider(
                  name="password"
                  rules="requiredPassword|minPassword:6|maxPassword:20"
                  vid="password")
                  div(slot-scope="{ errors, validated, invalid }")
                    input.form-control(
                      :class="validated && invalid ? 'input-error' : ''"
                      type="password"
                      v-model="password")
                    div.styled-errors(
                      role="alert"
                      v-if="validated && invalid")
                      p.errors-text {{ errors[0] }}

                label.register__form-label
                  span.register__form-label-title Подтверждение пароля
                ValidationProvider(
                  name="password_confirmation"
                  rules="requiredPassword|minPassword:6|maxPassword:20|confirmedPassword:password")
                  div(slot-scope="{ errors, validated, invalid }")
                      input.form-control(
                        :class="validated && invalid ? 'input-error' : ''"
                        type="password"
                        v-model="password_confirmation")
                      div.styled-errors(
                        role="alert"
                        v-if="validated && invalid")
                        p.errors-text {{ errors[0] }}

                button.register__form-submit.button.button_smaller.button_green(type="submit" style="cursor: pointer;") Сменить
</template>

<script>
import authMixin from 'mixins/auth'
import navigationMixin from 'mixins/navigation'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, confirmed, min, max } from 'vee-validate/dist/rules'

extend('requiredPassword', {
  ...required,
  message: 'Введите пароль'
})
extend('confirmedPassword', {
  ...confirmed,
  message: 'Пароли не совпадают'
})
extend('minPassword', {
  ...min,
  message: 'Пароль должен быть больше {length} символов'
})
extend('maxPassword', {
  ...max,
  message: 'Пароль должен быть не больше {length} символов'
})

export default {
  name: 'PasswordRecoveryRequest',
  data () {
    return {
      password: null,
      password_confirmation: null,
      creds: null
    }
  },
  created () {
    const params = this.$route.query
    this.creds = {
      'access-token': params['access-token'],
      client: params.client,
      expiry: params.expiry,
      'token-type': params['token-type'],
      uid: params.uid
    }

    if (!this.creds['access-token']) { this.goSignIn() }
  },
  methods: {
    updatePassword () {
      this.$refs.form.validate().then(success => {
        if (success) this.updatePasswordRequest()
      })
    },
    updatePasswordRequest () {
      const params = { password: this.password, password_confirmation: this.password_confirmation }
      this.$api.auth.updatePassword(params, { headers: this.creds })
        .then(({ headers }) => {
          const credentials = {
            accessToken: headers['access-token'],
            client: headers.client,
            expiry: headers.expiry,
            tokenType: headers['token-type'],
            uid: headers.uid
          }
          this.cookies.set(process.env.VUE_APP_COOKIE_CRED_NAME, JSON.stringify(credentials),
            {
              domain: process.env.VUE_APP_COOKIE_TLD,
              path: '/',
              sameSite: 'lax'
            })
          window.location = process.env.VUE_APP_DOCS_URL
        })
        .catch(() => (this.goSignIn()))
    }
  },
  components: {
    ValidationObserver,
    ValidationProvider
  },
  mixins: [authMixin, navigationMixin]
}
</script>
